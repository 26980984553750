import React, { SVGProps } from 'react';

export default function MembeersLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="141" height="40" viewBox="0 0 141 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.3295 40C31.3893 40 40.3551 31.0457 40.3551 20C40.3551 8.95431 31.3893 0 20.3295 0C9.26968 0 0.303925 8.95431 0.303925 20C0.303925 31.0457 9.26968 40 20.3295 40Z"
        fill="#07090A"
      />
      <path
        d="M15.0058 12.5071V31.2412H18.7019V12.5071H15.0058ZM8.34465 12.5071V31.1403L8.33179 31.2999H12.0408C12.0591 31.0082 12.072 30.7091 12.0665 30.3954V12.5071H8.34465Z"
        fill="#FFB62A"
      />
      <path
        d="M29.7149 12.6795C27.8944 12.6795 26.5148 13.5987 25.5761 15.4719V12.5071H21.9057V23.1703C21.9057 28.5863 24.1946 31.276 28.7468 31.243C33.2714 31.1402 35.5365 28.1093 35.5365 22.1135C35.5365 15.982 33.6058 12.852 29.7149 12.6795ZM28.6384 27.3938C26.5405 27.3938 25.5504 25.5554 25.5761 21.9081C25.6881 18.435 26.7059 16.7342 28.6935 16.7342C30.6812 16.7342 31.7008 18.435 31.7853 21.9081C31.7853 25.5554 30.7363 27.3938 28.6384 27.3938Z"
        fill="#FFB62A"
      />
      <path
        d="M12.0683 7.55713V12.5071H8.34464V9.37531C8.34464 8.21945 8.94902 7.64153 10.1633 7.55896H12.0683V7.55713Z"
        fill="#F5F6F6"
      />
      <path
        d="M18.7111 7.55713C18.7074 7.61034 18.7019 7.66354 18.7019 7.71675V12.5071H15.0058V7.66354C15.0058 7.62868 15.0021 7.59382 14.9966 7.55713H18.7111Z"
        fill="#F5F6F6"
      />
      <path
        d="M25.5761 10.0908V12.5071H21.9057V7.80844H23.7556C24.9699 7.80844 25.5761 8.59369 25.5761 10.0908Z"
        fill="#F5F6F6"
      />
      <path
        d="M45.3831 12.5713C45.3831 12.1127 45.5447 11.7164 45.8644 11.3788C46.1858 11.043 46.5753 10.8742 47.0346 10.8742H50.2034L53.4164 23.0254L56.5376 10.8742H61.4039V26.8306H58.3287V13.9473L55.0679 26.8324H51.7172L48.4583 13.9473V26.8324H45.3831V12.5713Z"
        fill="#F5F6F6"
      />
      <path
        d="M74.0317 23.0272C73.6643 24.4326 73.0526 25.4564 72.1947 26.0985C71.1843 26.7719 69.9902 27.1076 68.6143 27.1076C64.8502 26.9847 62.9672 24.8142 62.9672 20.5963C62.9672 16.3783 64.8649 14.2537 68.6602 14.2225C72.608 14.2225 74.4598 16.6517 74.2154 21.5136H66.1802C66.3033 23.4089 67.13 24.3574 68.6602 24.3574C69.395 24.3574 70.0674 24.0675 70.681 23.4859C70.9565 23.1795 71.3092 23.0272 71.7373 23.0272H74.0317ZM70.9106 19.2661C70.7269 17.6149 69.9608 16.7893 68.6143 16.7893C67.2677 16.7893 66.4723 17.6149 66.2261 19.2661H70.9106Z"
        fill="#F5F6F6"
      />
      <path
        d="M80.9647 16.9746C79.5869 16.9746 78.8833 17.6167 78.8521 18.901V26.8342H75.6832V19.5432C75.6832 15.9967 77.4578 14.2244 81.0088 14.2244C82.5997 14.2244 83.8397 14.5766 84.727 15.2793C85.6142 14.606 86.8542 14.2702 88.4451 14.2702C91.9961 14.2702 93.756 16.0426 93.7248 19.589V26.8801H92.1174C91.0758 26.8196 90.5559 26.2985 90.5559 25.3206V18.9469C90.4953 17.6626 89.7751 17.0205 88.3974 17.0205C87.0196 17.0205 86.316 17.6626 86.2848 18.9469V26.8342H83.1159V18.901C83.0626 17.6167 82.3425 16.9746 80.9647 16.9746Z"
        fill="#F5F6F6"
      />
      <path
        d="M98.6866 16.149C99.4821 14.8647 100.661 14.2226 102.223 14.2226C105.528 14.3455 107.181 16.5159 107.181 20.7339C107.181 24.8601 105.252 26.9553 101.396 27.0159C97.5091 27.0471 95.5655 25.1977 95.5655 21.4678V10.8761H97.127C98.1686 10.8761 98.6885 11.3953 98.6885 12.4356V16.149H98.6866ZM101.35 17.0205C99.6676 17.0205 98.7803 18.213 98.6866 20.5963C98.6554 23.1025 99.528 24.3556 101.304 24.3556C103.081 24.3556 103.968 23.1025 103.968 20.5963C103.906 18.213 103.033 17.0205 101.35 17.0205Z"
        fill="#F5F6F6"
      />
      <path
        d="M119.576 23.0272C119.208 24.4326 118.597 25.4564 117.739 26.0985C116.728 26.7719 115.534 27.1076 114.158 27.1076C110.394 26.9847 108.511 24.8142 108.511 20.5963C108.511 16.3783 110.409 14.2537 114.204 14.2225C118.152 14.2225 120.004 16.6517 119.759 21.5136H111.726C111.849 23.4089 112.676 24.3574 114.206 24.3574C114.941 24.3574 115.613 24.0675 116.227 23.4859C116.502 23.1795 116.855 23.0272 117.283 23.0272H119.576ZM116.455 19.2661C116.271 17.6149 115.505 16.7893 114.158 16.7893C112.812 16.7893 112.016 17.6149 111.77 19.2661H116.455Z"
        fill="#F5F6F6"
      />
      <path
        d="M132.202 23.0272C131.834 24.4326 131.222 25.4564 130.365 26.0985C129.354 26.7719 128.16 27.1076 126.784 27.1076C123.02 26.9847 121.137 24.8142 121.137 20.5963C121.137 16.3783 123.035 14.2537 126.83 14.2225C130.778 14.2225 132.63 16.6517 132.385 21.5136H124.352C124.475 23.4089 125.302 24.3574 126.832 24.3574C127.567 24.3574 128.239 24.0675 128.853 23.4859C129.128 23.1795 129.481 23.0272 129.909 23.0272H132.202ZM129.08 19.2661C128.897 17.6149 128.131 16.7893 126.784 16.7893C125.438 16.7893 124.642 17.6149 124.396 19.2661H129.08Z"
        fill="#F5F6F6"
      />
      <path
        d="M133.855 20.4128C133.947 16.4077 136.074 14.406 140.237 14.406H140.696V17.6608H139.64C137.895 17.6608 137.024 18.5634 137.024 20.3651V26.8306H133.857V20.4128H133.855Z"
        fill="#F5F6F6"
      />
    </svg>
  );
}
